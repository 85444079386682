import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";

const AllRecordsPO = () => {
    return (
        <main>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Purchase Order</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        <Link to="/purchasemanagement/createpurchaseorder">
                            <button type="button" className="btn btn-primary">
                            Create Purchase Order
                            </button>
                        </Link>
                    </div>
                </div>
            
                <section className="sales-box">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    min="2010-01-01"
                                    name="date-field"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">To Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="date-field"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 pt-1">
                            <div className="float-end mt-4">
                                <button type="button" className="btn btn-primary">Get Purchase Order</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-3 pb-3">
                    <div className="DataTable">
                        <DataTable
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            scrollable
                            tableStyle={{ minWidth: "60rem" }}
                            filterDisplay="row"
                            showGridlines
                            stripedRows
                        >
                            <Column field="" header="Purchase Order Date" filterMatchMode="contains" filter filterPlaceholder="Search Here..." style={{ minWidth: "5rem" }} />
                            <Column field="" header="Purchase Order Number" filterMatchMode="contains" filter filterPlaceholder="Search Here..." style={{ minWidth: "8rem" }} />
                            <Column field="" header="Action" style={{ minWidth: "4rem" }} />
                        </DataTable>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default AllRecordsPO;