import React, { Suspense, lazy } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ReallocateProvider } from "./context/ReallocateContext";
import { AutoAllocateProvider } from "./context/AutoAllocateContext";
import { ManualSOUpdateProvider } from "./context/ManualSOUpdateContext";
import { DebitCreditNoteProvider } from "./context/DebitCreditNoteContext";
import Loader from "./Components/Loader";
import CreateSOOrder from "./Pages/ManualSOOrder/Index";
import ViewCustomer from "./Pages/ViewCustomer";
import NewCustomer from "./Pages/NewCustomer";
import GRN from "./Pages/GRN/Index";
import ViewGRN from "./Pages/GRN/ViewGRN";
import StockInHand from "./Pages/Reports/Stock/StockInHand";
import SupplierMasterView from "./Pages/SupplierMaster/Index";
import SupplierMasterNew from "./Pages/SupplierMaster/NewSupplier";
import DSRDealerMapping from "./Pages/DSRDealerMapping/Index";
import PaytermLimitUpload from "./Pages/PaytermLimitUpload/Index";
import WarehouseMaster from "./Pages/WarehouseMaster/Index";
// import Print from "./Pages/InvoicePrint/NewPrintInvoice"
import WarehouseMasterNew from "./Pages/WarehouseMaster/NewWarehouse";
import PaymentAccountDefinition from "./Pages/PaymentAccountDefinition/Index";
import SearchModal from "./Pages/SearchModal/Index";
import EmployeeTable from "./Pages/EmployeeMaster/EmployeeTable";
import ProductMaster from "./Pages/ProductMaster/Index";
import ProductPages from "./Pages/ProductMaster/ProductPages";
import SalesReturn from "./Pages/SalesReturn/Index";
import AllWriteOffRecords from "./Pages/WriteOff/AllWriteOffRecords";
import WriteOffDetails from "./Pages/WriteOff/WriteOffDetails";
import OutstandingAdjustment from "./Pages/WriteOff/OutstandingAdjustment";
import AllSalesReturnTable from "./Pages/SalesReturn/AllSalesReturnTable";
import NewEmployee from "./Pages/EmployeeMaster/Index";
import DeliveryChallan from "./Pages/DeliveryChallan/Index";
import { DeliveryChallanProvider } from "./context/DeliveryChallanContext";
import { ReceiptsProvider } from "./context/ReceiptsContext";
import { ForgotPasswordProvider } from "./context/ForgotPasswordContext";

import { EmployeeMasterProvider } from "./context/EmployeeMasterContext";
import { StockAdjustmentProvider } from "./context/StockAdjustmentContext";
import StockTable from "./Pages/StockAdjustment/StockTable";
import NewStock from "./Pages/StockAdjustment/NewStock";
import { WareHouseMasterProvider } from "./context/WareHouseContext";
import { FreeInvoiceProvider } from "./context/FreeInvoiceContext";
import User from "./Pages/User/User";
import UserDetails from "./Pages/User/UserDetails";
import Master from "./Pages/Parameter_Settings/Master";
import Transaction from "./Pages/Parameter_Settings/Transaction";
import FieldLevelSettings from "./Pages/User_Groups/FieldLevelSettings";
import UserGroups from "./Pages/User_Groups/UserGroups";
import UserGroupsDetails from "./Pages/User_Groups/UserGroupsDetails";
import AllReceiptsRecords from "./Pages/Receipts/AllReceiptsRecords";
import NewReceipt from "./Pages/Receipts/NewReceipt";
import StockInHandNI from "./Pages/Reports/Stock/StockInhandNI";
import GrnDataExcel from "./Pages/Reports/GrnDataExcel";
import DeliveryChallanExcel from "./Pages/Reports/Sales/DeliveryChallanExcel";
import InvoiceDataExcel from "./Pages/Reports/Sales/InvoiceDataExcel";
import ReceiptMadeWithinPeriod from "./Pages/Reports/Credit_Management_Reports/ReceiptMadeWithinPeriod";
import StockAdjustmentReport from "./Pages/Reports/Stock/StockAdjusmentReport";
import Help from "./Pages/Help/Help";
import Details from "./Pages/DocumentAdjustment/Details";
import AllRecords from "./Pages/DocumentAdjustment/AllRecords";
import AllPurchaseReturnRecords from "./Pages/PurchaseReturn/AllPurchaseReturnRecords";
import PurchaseReturnDetails from "./Pages/PurchaseReturn/PurchaseReturnDetails";
import Maintenance from "./Pages/UnderMaintenance/Maintenance";
import AdvanceAdjustmentAllRecords from "./Pages/CustomerAdvanceAdjustment/AdvanceAdjustmentAllRecords";
import AdvanceAdjustmentDetails from "./Pages/CustomerAdvanceAdjustment/AdvanceAdjustmentDetails";
import { PurchaseReturnProvider } from "./context/PurchaseReturnContext";
import { WriteOffProvider } from "./context/WriteOffContext";
import OpenSalesOrder from "./Pages/Reports/Sales/OpenSalesOrder";
import GstReportExcel from "./Pages/Reports/Others/GstReportExcel";
import CustomerOutstandingStatus from "./Pages/Reports/Credit_Management_Reports/CustomerOutstandingStatus";
import CreditNoteAdjustment from "./Pages/Reports/Credit_Management_Reports/CreditNoteAdjustment";
import CustomerCreditNote from "./Pages/Reports/Credit_Management_Reports/CustomerCreditNote";
import CustomerDebitNote from "./Pages/Reports/Credit_Management_Reports/CustomerDebitNote";
import AnnexureAllRecords from "./Pages/InvoiceAnnexurePrint/AnnexureAllRecords";
import NonInventory from "./Pages/Reports/Others/NonInventory";
import PriceList from "./Pages/Reports/PriceList";
import AllCreationRecords from "./Pages/CreditNoteCreation/AllCreationRecords";
import PDAReceiptCreation from "./Pages/PDAReceipts/PDAReceiptCreation";
import PDAReceipts from "./Pages/PDAReceipts/PDAReceipts";
import DefaultSettings from "./Pages/DefaultSettings/DefaultSettings";
import SchemeShortFall from "./Pages/Reports/SchemeShortFall";
import { CNCreationProvider } from "./context/CreditNoteCreation";
import GRNnonInv from "./Pages/GRNnonINV/GRNnonInv";
import ViewGRNnonINV from "./Pages/GRNnonINV/ViewGRNnonINV";
import NonInvGrnExcel from "./Pages/Reports/NonInvGrnExcel";
import CreateChangeRequest from "./Pages/SegmentReactivationChangeRequest/CreateChangeRequest";
import SegmentReactivationAllRecords from "./Pages/SegmentReactivationChangeRequest/SegmentReactivationAllRecords";
import CreatePO from "./Pages/PuchaseOrder/CreatePO";
import AllRecordsPO from "./Pages/PuchaseOrder/AllRecrodsPO";
// const PersistLogin = lazy(() => import("./Components/PersistLogin"));
const RequireAuth = lazy(() => import("./Components/RequireAuth"));
const Dashboard = lazy(() => import("./Components/Dashboard/Index"));
const SalesOrderDetails = lazy(() => import("./Pages/SalesOrderDetails/Index"));
const SalesOrders = lazy(() => import("./Pages/SalesOrders/Index"));
const Invoice = lazy(() => import("./Pages/Invoice/Index"));
// const InvoicePrint = lazy(() => import("./Pages/InvoicePrint/Index"));
const AddEInvoice = lazy(() => import("./Pages/AddEInvoice/Index"));
const Login = lazy(() => import("./Pages/Login"));
const LoginLayout = lazy(() => import("./Layouts/LayoutLogin/LoginLayout"));
const Forget = lazy(() => import("./Pages/ForgotPassword/Index"));
const ResetPassword = lazy(() => import("./Pages/ResetPassword/Index"));
const MasterLayout = lazy(() => import("./Layouts/LayoutLogin/MasterLayout"));
const AddPaymentUPI = lazy(() => import("./Pages/AddPaymentUPI/Index"));
const DebitCreditNote = lazy(() => import("./Pages/DebitCreditNote/Index"));
const DebitCreditAllRecords = lazy(() =>
    import("./Pages/DebitCreditNote/DebitCreditAllRecords")
);
const FreeInvoice = lazy(() => import("./Pages/FreeInvoice/Index"));

function App() {
    return (
        <>
            <ToastContainer />
            <Suspense fallback={<Loader />}>
                <Routes>
                    {/* <Route path="/newprint" element={<Print/>}/> */}
                    <Route path='/' element={<LoginLayout />}>
                        <Route
                            index
                            element={
                                <ForgotPasswordProvider>
                                    <Login />
                                </ForgotPasswordProvider>
                            }
                        />
                        <Route
                            path='/forget-password'
                            element={
                                <ForgotPasswordProvider>
                                    <Forget />
                                </ForgotPasswordProvider>
                            }
                        />
                    </Route>
                    {/* <Route element={<PersistLogin />}> */}
                    <Route element={<RequireAuth />}>
                        <Route element={<MasterLayout />}>
                            <Route
                                path='/usermanagement/resetpassword'
                                element={<ResetPassword />}
                            />
                            <Route
                                path='/usermanagement/defaultsettings'
                                element={<DefaultSettings />}
                            />
                            <Route index path='/dashboard' element={<Dashboard />} />
                            <Route
                                path='/salesmanagement/salesorder/details'
                                element={
                                    <AutoAllocateProvider>
                                        <ReallocateProvider>
                                            <SalesOrderDetails />
                                        </ReallocateProvider>
                                    </AutoAllocateProvider>
                                }
                            />
                            <Route
                                path='/salesmanagement/salesorder/manualsalesorder'
                                element={
                                    <AutoAllocateProvider>
                                        <ReallocateProvider>
                                            <ManualSOUpdateProvider>
                                                <CreateSOOrder />
                                            </ManualSOUpdateProvider>
                                        </ReallocateProvider>
                                    </AutoAllocateProvider>
                                }
                            />
                            <Route
                                path='/salesmanagement/salesorder'
                                element={<SalesOrders />}
                            />
                            {/* <Route path="/invoice" element={<Invoice />} /> */}
                            <Route
                                path='/salesmanagement/freeinvoice'
                                element={
                                    <FreeInvoiceProvider>
                                        <FreeInvoice />
                                    </FreeInvoiceProvider>
                                }
                            />
                            <Route path='/add-e-invoice' element={<AddEInvoice />} />
                            <Route path='/add-payment-UPI' element={<AddPaymentUPI />} />
                            <Route
                                path='/masters/customermaster'
                                element={<ViewCustomer />}
                            />
                            <Route
                                path='/masters/customermaster/details'
                                element={<NewCustomer />}
                            />

                            <Route
                                path='/salesmanagement/deliverychallan'
                                element={
                                    <DeliveryChallanProvider>
                                        <DeliveryChallan />
                                    </DeliveryChallanProvider>
                                }
                            />

                            <Route
                                path='/salesmanagement/invoice'
                                element={
                                    <DeliveryChallanProvider>
                                        <Invoice />
                                    </DeliveryChallanProvider>
                                }
                            />

                            <Route
                                path='/inventorymanagement/stockadjustment/details'
                                element={
                                    <StockAdjustmentProvider>
                                        <NewStock />
                                    </StockAdjustmentProvider>
                                }
                            />

                            <Route
                                path='/masters/employeemaster/details'
                                element={
                                    <EmployeeMasterProvider>
                                        <NewEmployee />
                                    </EmployeeMasterProvider>
                                }
                            />
                            <Route
                                path='/masters/employeemaster'
                                element={
                                    <EmployeeMasterProvider>
                                        <EmployeeTable />
                                    </EmployeeMasterProvider>
                                }
                            />
                            <Route
                                path='/masters/productmaster'
                                element={<ProductMaster />}
                            />
                            <Route
                                path='/masters/productmaster/details'
                                element={<ProductPages />}
                            />
                            <Route
                                path='/purchasemanagement/goodsreceiptnote'
                                element={<GRN />}
                            />
                            <Route
                                path='/purchasemanagement/goodsreceiptNoninventory'
                                element={<GRNnonInv />}
                            />
                            <Route
                                path='/purchasemanagement/goodsreceiptNoninventoryView'
                                element={<ViewGRNnonINV />}
                            />
                            <Route
                                path='/reports/stock/stockinhand'
                                element={<StockInHand />}
                            />
                            <Route path='/reports/stock/stockinhandnonin' element={<StockInHandNI />} />
                            <Route path='/reports/purchase/nonInvGrnExcel' element={<NonInvGrnExcel />} />
                            <Route
                                path='/reports/stock/stockadjustmentreport'
                                element={<StockAdjustmentReport />}
                            />
                            <Route
                                path='/reports/purchase/grndatatoexcel'
                                element={<GrnDataExcel />}
                            />
                            <Route
                                path='/salesmanagement/salesreturn'
                                element={<SalesReturn />}
                            />
                            <Route
                                path='/reports/others/gstreporttoexcel'
                                element={<GstReportExcel />}
                            />
                            <Route
                                path='/salesmanagement/salesreturn/details'
                                element={<AllSalesReturnTable />}
                            />
                            <Route
                                path='/reports/others/noninventory'
                                element={<NonInventory />}
                            />
                            <Route
                                path='/creditmanagement/debitcreditnote/details'
                                element={
                                    <DebitCreditNoteProvider>
                                        <DebitCreditNote />
                                    </DebitCreditNoteProvider>
                                }
                            />
                            <Route
                                path='/creditmanagement/debitcreditnote'
                                element={
                                    <DebitCreditNoteProvider>
                                        <DebitCreditAllRecords />
                                    </DebitCreditNoteProvider>
                                }
                            />
                            {/* <Route path="/viewgoodsreceiptnode" element={<ViewGRN />} /> */}
                            <Route
                                path='/purchasemanagement/goodsreceiptnote/details'
                                element={<ViewGRN />}
                            />
                            <Route
                                path='/masters/suppliermaster'
                                element={
                                    <EmployeeMasterProvider>
                                        <SupplierMasterView />
                                    </EmployeeMasterProvider>
                                }
                            />
                            <Route
                                path='/masters/suppliermaster/details'
                                element={
                                    <EmployeeMasterProvider>
                                        <SupplierMasterNew />
                                    </EmployeeMasterProvider>
                                }
                            />
                            <Route
                                path='/masters/sedealermapping'
                                element={<DSRDealerMapping />}
                            />
                            <Route
                                path='/masters/paytermlimitupload'
                                element={<PaytermLimitUpload />}
                            />
                            <Route
                                path='/masters/warehousemaster'
                                element={
                                    <WareHouseMasterProvider>
                                        <WarehouseMaster />
                                    </WareHouseMasterProvider>
                                }
                            />

                            <Route
                                path='/inventorymanagement/stockadjustment'
                                element={
                                    <StockAdjustmentProvider>
                                        <StockTable />
                                    </StockAdjustmentProvider>
                                }
                            />

                            <Route
                                path='/masters/warehousemaster/details'
                                element={
                                    <WareHouseMasterProvider>
                                        <WarehouseMasterNew />
                                    </WareHouseMasterProvider>
                                }
                            />
                            <Route
                                path='/masters/paymentaccountdefinition'
                                element={<PaymentAccountDefinition />}
                            />
                            <Route
                                path='/masters/segmentreactivationchangerequest'
                                element={<CreateChangeRequest />}
                            />
                            <Route
                                path='/masters/segmentreactivationchangerequestallrecords'
                                element={<SegmentReactivationAllRecords />}
                            />
                            <Route
                                path='/purchasemanagement/purchaseorder'
                                element={<AllRecordsPO/>}
                            />
                            <Route
                                path='/purchasemanagement/createpurchaseorder'
                                element={<CreatePO/>}
                            />
                            <Route path='/searchmodal' element={<SearchModal />} />
                            <Route path='/user' element={<User />} />
                            <Route path='/userdetails' element={<UserDetails />} />
                            <Route
                                path='/usermanagement/parametersettings/master'
                                element={<Master />}
                            />
                            
                            <Route
                                path='/usermanagement/parametersettings/transaction'
                                element={<Transaction />}
                            />
                            <Route
                                path='/usergroup/fieldlevelsettings'
                                element={<FieldLevelSettings />}
                            />
                            <Route path='/usergroups' element={<UserGroups />} />
                            <Route
                                path='/usergroupsdetails'
                                element={<UserGroupsDetails />}
                            />
                            <Route
                                path='/reports/sales/deliverychallantoexcel'
                                element={<DeliveryChallanExcel />}
                            />
                            <Route
                                path='/reports/sales/invoicedatatoexcel'
                                element={<InvoiceDataExcel />}
                            />
                            <Route
                                path='/reports/sales/opensalesorder'
                                element={<OpenSalesOrder />}
                            />
                            <Route
                                path='/reports/creditmanagementreports/receiptmadewithinperiod'
                                element={<ReceiptMadeWithinPeriod />}
                            />
                            <Route path='/help' element={<Help />} />
                            <Route
                                path='/creditmanagement/documentadjustment/details'
                                element={<Details />}
                            />
                            <Route
                                path='/creditmanagement/documentadjustment'
                                element={<AllRecords />}
                            />
                            <Route path='/maintenance' element={<Maintenance />} />
                            <Route
                                path='/reports/creditmanagementreports/customeroutstandingstatus'
                                element={<CustomerOutstandingStatus />}
                            />
                            <Route
                                path='/reports/creditmanagementreports/creditnoteadjustment'
                                element={<CreditNoteAdjustment />}
                            />
                            <Route
                                path='/reports/creditmanagementreports/customercreditnote'
                                element={<CustomerCreditNote />}
                            />
                            <Route
                                path='/reports/creditmanagementreports/customerdebitnote'
                                element={<CustomerDebitNote />}
                            />
                            <Route
                                path='/salesmanagement/invoiceannexureprint'
                                element={
                                    <DeliveryChallanProvider>
                                        <AnnexureAllRecords />
                                    </DeliveryChallanProvider>
                                }
                            />
                            <Route path='/reports/pricelist' element={<PriceList />} />
                            <Route
                                path='/reports/schemeshortfall'
                                element={<SchemeShortFall />}
                            />
                            <Route
                                path='/creditmanagement/creditnotecreation'
                                element={
                                    <CNCreationProvider>
                                        <AllCreationRecords />
                                    </CNCreationProvider>
                                }
                            />
                            <Route
                                path='/creditmanagement/pdareceiptcreation'
                                element={<PDAReceiptCreation />}
                            />
                            <Route
                                path='/creditmanagement/pdareceipts'
                                element={<PDAReceipts />}
                            />
                            <Route
                                path='/creditmanagement/writeoff'
                                element={
                                    <WriteOffProvider>
                                        <AllWriteOffRecords />
                                    </WriteOffProvider>
                                }
                            />
                            <Route
                                path='/creditmanagement/writeoff/details'
                                element={
                                    <WriteOffProvider>
                                        <WriteOffDetails />
                                    </WriteOffProvider>
                                }
                            />
                            <Route
                                path='/creditmanagement/writeoff/outstandingadjustment'
                                element={
                                    <WriteOffProvider>
                                        <OutstandingAdjustment />
                                    </WriteOffProvider>
                                }
                            />
                            <Route
                                path='/creditmanagement/customeradvanceadjustment'
                                element={<AdvanceAdjustmentAllRecords />}
                            />
                            <Route
                                path='/creditmanagement/customeradvanceadjustment/details'
                                element={<AdvanceAdjustmentDetails />}
                            />
                            <Route
                                path='/purchasemanagement/purchasereturn'
                                element={
                                    <PurchaseReturnProvider>
                                        <AllPurchaseReturnRecords />
                                    </PurchaseReturnProvider>
                                }
                            />
                            <Route
                                path='/purchasemanagement/purchasereturn/details'
                                element={
                                    <PurchaseReturnProvider>
                                        <PurchaseReturnDetails />
                                    </PurchaseReturnProvider>
                                }
                            />
                            <Route
                                path='/creditmanagement/receipts'
                                element={
                                    <ReceiptsProvider>
                                        <AllReceiptsRecords />
                                    </ReceiptsProvider>
                                }
                            />
                            <Route
                                path='/creditmanagement/receipts/details'
                                element={
                                    <ReceiptsProvider>
                                        <NewReceipt />
                                    </ReceiptsProvider>
                                }
                            />
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default App;
