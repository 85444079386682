import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";
import { Link } from "react-router-dom";
import { AutoComplete } from "primereact/autocomplete";
import { format } from "date-fns";

const CreatePO = () => {
    return (
        <main>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Create Purchase Order</h3>
                </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                    <div className="float-end">
                        <Link to="/purchasemanagement/purchaseorder">
                            <button type="button" className="btn btn-outline-primary">
                                <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All
                Records
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <section className="sales-box">
                <div className="row d-flex justify-content-end align-items-end">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="mb-3">
                            <label className="form-label">Purchase Order No</label>
                            <div className="d-block">
                                <input className="form-control" disabled/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="mb-3">
                            <label className="form-label">Purchase Order Date</label>
                            <div className="d-block">
                                <input type="Date" className="form-control" defaultValue={format(new Date(), "yyyy-MM-dd")} disabled/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12">
                        <label className="form-label">
              Product <span className="mandatory">*</span>
                        </label>
                        <div className="d-block">
                            <AutoComplete dropdown />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <label className="form-label">Purchase Quantity</label>
                        <input className="form-control" />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="float-end">
                            <button type="button" className="btn btn-outline-primary me-3">
                Reallocate
                            </button>
                            <button type="button" className="btn btn-primary me-3">
                Add to Cart
                            </button>
                            <button type="button" className="btn btn-cancel-red">
                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sales-box">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row order-summery">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h3> Purchase Order Summary</h3>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className=" order-detail-container">
                                    <div className="order-box1">Net Volume (Ltr)</div>
                                    <div className="order-box text-end">
                                        <span>1</span>
                                    </div>
                                </div>
                                <div className=" order-detail-container">
                                    <div className="order-box1">Net Weight (kg):</div>
                                    <div className="order-box text-end">
                                        <span>1</span>
                                    </div>
                                </div>
                                <hr className="borderMobileView"></hr>
                                <div className=" order-detail-container">
                                    <div className="order-box1">Gross Value:</div>
                                    <div className="order-box text-end">
                                        <span>₹ 0.00</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="order-detail-container">
                                    <div className="order-box">Amount Before Tax:</div>
                                    <div className="order-box text-end">
                                        <span>₹ 0.00</span>
                                    </div>
                                </div>
                                <div className="order-detail-container border-bottom">
                                    <div className="order-box">GST Amount:</div>
                                    <div className="order-box text-end">
                                        <span>₹ 0.00</span>
                                    </div>
                                </div>
                                <div className="total order-detail-container">
                                    <div className="order-box ">Estimated Total Amount: </div>
                                    <div className="order-box text-end">
                                        <span className="">₹ 0.00</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="DataTable mb-4">
                <DataTable
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    scrollHeight="80vh"
                    scrollable
                    tableStyle={{ minWidth: "60rem" }}
                    filterDisplay="row"
                    showGridlines
                    stripedRows
                >
                    <Column field="" header="Serial No" style={{ minWidth: "5rem" }} />
                    <Column field="" header="Product Code" style={{ minWidth: "8rem" }} />
                    <Column field="" header="Product Name" style={{ minWidth: "4rem" }} />
                    <Column
                        field=""
                        header="Purchase Quantity"
                        style={{ minWidth: "4rem" }}
                    />
                    <Column field="" header="Action" style={{ minWidth: "4rem" }} />
                </DataTable>
            </div>
        </main>
    );
};

export default CreatePO;
